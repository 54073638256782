<template lang="pug">
.ui-loader(:class="mini && '_mini'")
  .circular
    svg(viewBox="0 0 50 50")
      circle.path(cx="25" cy="25" r="20" fill="none")
  span(v-if="label") {{ label }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "UiLoader",
  props: {
    label: {
      type: String,
      default: '',
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
$loader-spinner-size: 42px;
$mini-spinner-size: 20px;

.ui-loader {
  display: flex;
  flex-flow: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  height: 240px;

  span {
    font-size: 14px;
    line-height: 20px;
  }

  &._mini {
    height: 30px;
    z-index: 0;
    width: 100%;

    .circular {
      height: $mini-spinner-size;
      width: $mini-spinner-size;
    }
  }
}

.circular {
  display: inline;
  height: $loader-spinner-size;
  width: $loader-spinner-size;
  animation: loading-rotate 2s linear infinite;

  .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: var(--default-blue-color);
    stroke-linecap: round;
  }
}
</style>
